// Topbar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Offcanvas, OffcanvasBody } from 'reactstrap';
import { FaGithub, FaDiscord } from 'react-icons/fa';
import logodark from '../../assets/images/logo-dark.png';
import floomLogo from '../../assets/images/floom-logo.png';
import RightSidebar from './RightSidebar';
import withRouter from '../../common/data/withRouter';
import { useAuth } from '../../pages/Home/auth/AuthContext';
import LoginModal from '../../pages/Home/LoginModal';
import LoginStateContainer from '../../pages/Home/LoginStateContainer';

const Topbar = (props) => {
  const { isLoggedIn, handleLogout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleLine = () => setIsOpen(!isOpen);
  const toggleOffcanvas = () => setIsOffcanvasOpen(!isOffcanvasOpen);
  const closeOffcanvas = () => setIsOffcanvasOpen(false);

  return (
    <React.Fragment>
      <header id="topnav" className="defaultscroll sticky">
        <Container>
          <div>
              <Link className="logo" to="/">
                <img src={floomLogo} height="28" className="logo-light-mode" alt="" />
                <img src={floomLogo} height="28" className="logo-dark-mode" alt="" />
              </Link>
          </div>

          <div className="menu-extras">
            <div className="menu-item">
              <Link to="#" onClick={toggleLine} className={isOpen ? 'navbar-toggle open' : 'navbar-toggle'}>
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <ul className="buy-button list-inline mb-0">

              <Link style={{ margin: '10px' }} to="/marketplace" className="btn btn-outline-primary">
                AI Marketplace
              </Link>
              <Link style={{ margin: '10px' }} to="https://floomai.github.io/" className="btn btn-outline-primary">
              📚 Documentation
              </Link>
            <LoginStateContainer />
          </ul>

          <div id="navigation" style={{ display: isOpen ? 'block' : 'none' }}>
            <ul className="navigation-menu nav-dark" id="top-menu">
              <MyToolbar />
            </ul>
          </div>
        </Container>
      </header>

      <Offcanvas isOpen={isOffcanvasOpen} direction="right" toggle={toggleOffcanvas}>
        <OffcanvasBody>
          <RightSidebar onClose={closeOffcanvas} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

const MyToolbar = () => (
  <ul className="mobile-only nav-dark" id="top-menu">
    <li>
      <Link to="/" className="sub-menu-item">
        AI Marketplace
      </Link>
    </li>
     <li>
      <Link to="https://floomai.github.io/" className="sub-menu-item">
        Documentation
      </Link>
    </li>
  </ul>
);

export default withRouter(Topbar);
