import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import axios from 'axios';

const FunctionTester = ({ functionDetails }) => {
  const [formData, setFormData] = useState(
    functionDetails.parameters.reduce((acc, param) => {
      acc[param.name] = param.defaultValue || ''; // Initialize form data with defaultValue if available
      return acc;
    }, {})
  );
  const [prompt, setPrompt] = useState(''); // Add prompt state
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value); // Update prompt state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResponse(null);

    // i need the parameters to have defaultValue if empty, just like input
    // defaultValue={param.defaultValue || ''}

    const formDataToSend = functionDetails.parameters.reduce((acc, param) => {
      acc[param.name] = formData[param.name] || param.defaultValue || '';
      return acc;
    }, {});

    try {
      // Construct HTTP request body based on form data and prompt
      const requestBody = {
        function: functionDetails.id,
        prompt,
        parameters: formDataToSend,
      };

      // Make API call using Axios
      const result = await axios.post('https://api.floom.ai/v1/functions/featured/run', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // response should be the value with key result.data.result
      if(result.data && result.data.result){
        setResponse(result.data);
      }
    } catch (err) {
      console.error('Error occurred:', err);
      setError('Failed to run the function.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="border-0 rounded shadow">
      <CardBody>
        <h5 className="widget-title">Demo</h5>
        <Form onSubmit={handleSubmit}>
          {/* Prompt Input */}
          <FormGroup>
            <Label for="prompt">Prompt <span className="text-danger">*</span></Label>
            <Input
              type="text"
              name="prompt"
              id="prompt"
              placeholder="Enter your prompt"
              value={prompt}
              onChange={handlePromptChange}
              required
            />
          </FormGroup>

          {/* Parameters Input */}
          {functionDetails.parameters.map((param) => (
            <FormGroup key={param.name}>
              <Label for={param.name}>
                {param.name} {param.required && <span className="text-danger">*</span>}
              </Label>
              <Input
                type="text"
                name={param.name}
                id={param.name}
                placeholder={`Enter ${param.name}`}
                defaultValue={param.defaultValue || ''}
                onChange={handleInputChange}
                required={param.required}
              />
            </FormGroup>
          ))}
          <Button type="submit" color="primary" disabled={isLoading}>
            {isLoading ? 'Running...' : 'Run'}
          </Button>
        </Form>

        {/* Response Display */}
        {response && (
          <Alert color="success" className="mt-4">
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </Alert>
        )}

        {/* Error Display */}
        {error && (
          <Alert color="danger" className="mt-4">
            {error}
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

export default FunctionTester;
