import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import Images
import marketplace01 from "../../assets/images/nft/1.jpg";
import marketplace02 from "../../assets/images/nft/2.jpg";
import marketplace03 from "../../assets/images/nft/3.jpg";
import marketplace04 from "../../assets/images/nft/4.jpg";
import marketplace05 from "../../assets/images/nft/5.jpg";
import marketplace06 from "../../assets/images/nft/6.jpg";
import client01 from "../../assets/images/client/01.jpg";
import client02 from "../../assets/images/client/02.jpg";
import client04 from "../../assets/images/client/04.jpg";
import axios from 'axios';

const Products = () => {
    // const productsData = [
    //     {
    //         id: 1,
    //         cardImg: marketplace01,
    //         badge: 231,
    //         title: "chat-pdf-pro",
    //         description: "Create a chatbot with PDF files (hosted on AWS S3), concise, fast and appropriate...",
    //         img: client01,
    //         author: "Kumar Lichi",
    //         version: "v1.12",
    //         rating: 4,
    //         downloads: [0, 100, 100, 350, 300, 350, 400, 450, 300, 350],
    //         runtime: { language: 'Python', framework: 'LangChain/0.3.5' },
    //         parameters: [
    //             { name: 'pdf_url', description: 'URL of the PDF to process', required: true },
    //             { name: 'model', description: 'Model to use for processing', required: false }
    //         ]
    //     },
    //     {
    //         id: 2,
    //         cardImg: marketplace02,
    //         badge: 232,
    //         title: "extract-image-objects",
    //         description: "Extract objects from any given image, return in JSON array format",
    //         img: client02,
    //         author: "Lana GenAI",
    //         version: "v1.93",
    //         rating: 5,
    //         downloads: [0, 0, 500, 500, 200, 400, 500, 600],
    //         runtime: { language: 'Python', framework: 'LangChain/0.3.5' },
    //         parameters: [
    //             { name: 'image_url', description: 'URL of the image to process', required: true },
    //             { name: 'object_type', description: 'Type of objects to extract', required: false }
    //         ]
    //     },
    //     {
    //         id: 3,
    //         cardImg: marketplace03,
    //         badge: 232,
    //         title: "translate-code-turbo",
    //         description: "Translate any code to any code, includes full validation, high speed",
    //         img: client02,
    //         author: "Lana GenAI",
    //         version: "v0.58",
    //         rating: 3,
    //         downloads: [0, 100, 100, 300, 200, 300, 450, 500],
    //         runtime: { language: 'Python', framework: 'LangChain/0.3.5' },
    //         parameters: [
    //             { name: 'source_code', description: 'Code to translate', required: true },
    //             { name: 'target_language', description: 'Target programming language', required: true }
    //         ]
    //     },
    //     {
    //         id: 4,
    //         cardImg: marketplace04,
    //         badge: 232,
    //         title: "extract-text-emotions",
    //         description: "Analyzes text and returns an array of detected emotions, emotional tone, and sentiment expressed in the content",
    //         img: client04,
    //         author: "AIMaster91",
    //         version: "v0.83",
    //         rating: 4,
    //         downloads: [0, 480, 200, 230, 290, 340, 400, 480],
    //         runtime: { language: 'Python', framework: 'LangChain/0.3.5' },
    //         parameters: [
    //             { name: 'text', description: 'Text to analyze', required: true },
    //             { name: 'emotion_model', description: 'Model for emotion detection', required: false }
    //         ]
    //     },
    //     {
    //         id: 5,
    //         cardImg: marketplace05,
    //         badge: 232,
    //         title: "extract-physical-addresses",
    //         description: "Scans text and returns an array of physical addresses, extracting detailed location information from the provided content",
    //         img: client04,
    //         author: "AIMaster91",
    //         version: "v1.21",
    //         rating: 4,
    //         downloads: [0, 90, 90, 220, 150, 200, 250, 400],
    //         runtime: { language: 'Python', framework: 'LangChain/0.3.5' },
    //         parameters: [
    //             { name: 'text_content', description: 'Text containing addresses to extract', required: true }
    //         ]
    //     },
    //     {
    //         id: 6,
    //         cardImg: marketplace06,
    //         badge: 232,
    //         title: "text-to-sql",
    //         description: "Converts natural language text into SQL queries, enabling users to interact with databases using plain language",
    //         img: client04,
    //         author: "AIMaster91",
    //         version: "v2.10",
    //         rating: 3,
    //         downloads: [0, 100, 100, 500, 200, 600, 700, 1200],
    //         runtime: { language: 'Python', framework: 'LangChain/0.3.5' },
    //         parameters: [
    //             { name: 'language', description: 'The language to translate to', required: true }
    //         ]
    //     }
    //     // ...other products
    // ];

    const [productsData, setProductsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://api.floom.ai/v1/functions/featured');
                setProductsData(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
      <React.Fragment>
          <Container style={{ marginBottom: '100px', marginTop: '100px' }}>
              <Row className="justify-content-center">
                  <Col className="col-12">
                      <div className="section-title mb-4 pb-2 text-center">
                          <h4 className="title mb-4">TOP AI FUNCTIONS</h4>
                          <p className="text-muted para-desc mb-0 mx-auto">
                              Fully portable & free <span className="text-primary fw-bold">AI Functions</span>, developed by our community.
                          </p>
                      </div>
                  </Col>
              </Row>

              <Row>
                  {productsData.map((item, key) => (
                    <Col key={key} lg={6} md={6} sm={12} className="col-12 mt-4 pt-2">
                        <Link to={`/function`} state={item} className="text-decoration-none">
                            <Card className="marketplace-item rounded shadow overflow-hidden">
                                <CardBody className="p-3">
                                    <div className="d-flex align-items-center">
                                        {/* Adjust this line based on actual data */}
                                        {/*<img src={`path_to_placeholder_image_or_null`} className="img-fluid item-img rounded-circle me-3" alt="" style={{ width: '50px', height: '50px' }} />*/}
                                        <div>
                                            <h5 className="title text-dark">{item.name}</h5>
                                            <p className="text-muted mb-0">{item.description}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        {/*<div className="d-flex align-items-center">*/}
                                        {/*    {[...Array(5)].map((star, i) => (*/}
                                        {/*      <i key={i} className={`mdi mdi-star${i < item.rating ? '' : '-outline'} text-warning`}></i>*/}
                                        {/*    ))}*/}
                                        {/*</div>*/}
                                        {/*<span className="badge rounded-md bg-light shadow">{item.downloads}</span>*/}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <div className="d-flex align-items-center">
                                            <span>{item.author}</span>
                                        </div>
                                        <span className="text-muted">{item.version}</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                  ))}
              </Row>
          </Container>
      </React.Fragment>
    );
}

export default Products;
