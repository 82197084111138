import React, { useEffect, useState } from 'react';
import {
    Container, Row, Col, Card, CardBody, Badge, ListGroup, ListGroupItem, Button,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import LoginModal from '../LoginModal';
import MultiLangCodeBlock from '../MultiLangCodeBlock';
import FunctionTester from './components/FunctionTester';

const codeBlocks = [
    {
        language: 'JavaScript',
        code: `const { FloomClient, DataTransferType } = require('floomai');

const main = async () => {
    const floomClient = new FloomClient("floom-endpoint", "api-key");

    try {
        const response = await floomClient.run(
            "pipeline-id", 
            "", 
            "Your input", 
            null, 
            DataTransferType.Base64
        );
        console.log('Floom response:', response.values[0].value);
    } catch (error) {
        console.error('Error:', error);
    }
};`,
    },
    {
        language: 'Python',
        code: `from floom import FloomClient

def run_pipeline():
    floom_client = FloomClient(url="floom-endpoint", api_key="api-key")
    response = floom_client.run(pipelineId="pipeline-id", prompt="Your input")

    if response.success:
        for value in response.values:
            print("Response:", value.value)
    else:
        print(f"Error: {response.error.detail}")`,
    },
    {
        language: 'Go',
        code: `package main

import (
    "fmt"
    "github.com/FloomAI/FloomSDK-Go"
)

func main() {
    floomClient := floom.NewFloomClient("floom-endpoint", "api-key")
    response, err := floomClient.Run("pipeline-id", "chat-id", "Your input", nil, floom.Base64)
    
    if err != nil {
        fmt.Println("Error:", err)
    } else {
        fmt.Println("Response:", response.Values[0].Value)
    }
}`,
    },
    {
        language: 'C#',
        code: `using ai.floom;
using System;

public class Example {
    public static void Main() {
        var floomClient = new FloomClient("floom-endpoint", "api-key");

        try {
            var response = floomClient.Run("pipeline-id", "chat-id", "Your input", null);
            Console.WriteLine("Response: " + response.Values[0].Value);
        } catch (Exception e) {
            Console.WriteLine("Error: " + e.Message);
        }
    }
}`,
    },
    {
        language: 'Rust',
        code: `use floom::FloomClient;

#[tokio::main]
async fn main() {
    let floom_client = FloomClient::new("floom-endpoint".to_string(), "api-key".to_string());

    match floom_client.run("pipeline-id".to_string(), "chat-id".to_string(), "Your input".to_string(), None).await {
        Ok(response) => println!("Response: {:?}", response),
        Err(e) => println!("Error: {:?}", e),
    }
}`,
    },
    {
        language: 'HTTP',
        code: `POST /v1/pipelines/run
Host: floom-endpoint
Content-Type: application/json
Authorization: Bearer api-key

{
  "pipeline_id": "pipeline-id",
  "prompt": "Your input"
}`
    }
];

const DownloadsGraph = ({ data }) => {
    // Ensure data is valid and has at least two points
    if (!data || data.length < 2) {
        return null; // or return a placeholder if needed
    }

    // Find the maximum value in the data, with a fallback of 1 to prevent division by zero
    const maxVal = Math.max(...data, 1);

    // Map the data to SVG points with smoother curves
    const points = data.map((point, index) => {
        const x = (index / (data.length - 1)) * 100; // X-axis position as a percentage
        const y = 100 - (point / maxVal) * 100; // Y-axis position, inverted because SVG y=0 is top
        return { x, y: isNaN(y) ? 100 : y }; // If y is NaN, set it to 100 (bottom of graph)
    });

    // Use cubic Bezier curve (C) to create a smooth path
    let pathData = `M ${points[0].x},${points[0].y}`;
    for (let i = 1; i < points.length - 1; i++) {
        const controlX = (points[i].x + points[i - 1].x) / 2;
        pathData += ` C ${controlX},${points[i - 1].y} ${controlX},${points[i].y} ${points[i].x},${points[i].y}`;
    }
    pathData += ` L 100,100 Z`; // Draw closing line at the bottom

    return (
      <svg viewBox="0 0 100 100" preserveAspectRatio="none" style={{ width: '100%', height: '100%' }}>
          <defs>
              <linearGradient id="fill-gradient" gradientTransform="rotate(90)">
                  <stop offset="5%" stopColor="#007bff" stopOpacity="0.4" />
                  <stop offset="100%" stopColor="#007bff" stopOpacity="0" />
              </linearGradient>
          </defs>
          <path d={pathData} fill="url(#fill-gradient)" stroke="#007bff" strokeWidth="1.5" vectorEffect="non-scaling-stroke" />
      </svg>
    );
};



const DeveloperFunctionProductPage = () => {
    const location = useLocation();
    const [functionDetails, setFunctionDetails] = useState({
        title: 'Text-to-SQL',
        description: 'Converts natural language text into SQL queries, enabling users to interact with databases using plain language',
        author: 'AIMaster91',
        img: 'marketplace06', // Replace with actual image path
        version: 'v2.10',
        rating: 3,
        downloads: [0, 100, 500, 200, 600, 700, 1200],
        runtimeLanguage: 'Python',
        runtimeFramework: 'Langchain/2.1',
        parameters: [
            { name: 'language', description: 'The language to translate to', required: true }
        ]
    });

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component load
        if (location.state) {
                // place defaults if function properties are null/empty
                // check for version/rating/downloads
                if (!location.state.version || location.state.version === "") {
                    location.state.version = "v1.0";
                }
                if (!location.state.rating || location.state.rating === 0) {
                    location.state.rating = 5;
                }
                if (!location.state.downloads || location.state.downloads.length === 0) {
                    location.state.downloads = [0, 100, 100, 500, 200, 600, 700, 1200];
                }
            setFunctionDetails(location.state);
        }
    }, [location.state]);

    return (
      <React.Fragment>
          {/* Header Section */}
          <section className="function-product-page">
              <Container>
                  <Row>
                      <Col lg="12">
                          <Card className="public-profile border-0 rounded shadow" style={{ zIndex: "1" }}>
                              <CardBody>
                                  <Row className="align-items-center">
                                      <Col lg="10" md="9">
                                          <h3 className="title mb-0">{functionDetails.name}</h3>
                                          <small className="text-muted h6">{functionDetails.description}</small>
                                      </Col>
                                  </Row>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </Container>
          </section>

          {/* Details Section */}
          <section className="section mt-4">
              <Container>
                  <Row>
                      <Col lg="4" md="6">
                          {/* Sidebar Widgets */}
                          <Card className="border-0 rounded shadow">
                              <CardBody>
                                  <h5 className="widget-title">Details</h5>
                                  <ul className="list-unstyled">
                                      <li>
                                          <strong>Author:</strong> {functionDetails.author}
                                      </li>
                                      <li>
                                          <strong>Version:</strong> {functionDetails.version}
                                      </li>
                                      <li>
                                          <strong>Total Deployments:</strong> {functionDetails.downloads.reduce((a, b) => a + b, 0)}
                                      </li>
                                      <li>
                                          <strong>Rating:</strong> {functionDetails.rating} / 5
                                      </li>
                                  </ul>
                              </CardBody>
                          </Card>

                          {/* Parameters */}
                          <Card className="border-0 rounded shadow mt-4">
                              <CardBody>
                                  <h5 className="widget-title">Parameters</h5>
                                  <ListGroup>
                                      {functionDetails.parameters.map((param, index) => (
                                        <ListGroupItem key={index}>
                                            <strong>{param.name}</strong> - {param.description}
                                            {param.required && <Badge color="danger" className="ms-2">Required</Badge>}
                                        </ListGroupItem>
                                      ))}
                                  </ListGroup>
                              </CardBody>
                          </Card>
                      </Col>

                      <Col lg="8" md="6">
                          {/* Function Tester */}
                          <FunctionTester functionDetails={functionDetails} />

                          {/* Downloads Graph */}
                          <Card className="border-0 rounded shadow mt-4">
                              <CardBody>
                                  <h5 className="widget-title">Deployments Last Month</h5>
                                  <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                          <h6 className="text-muted mb-0">385</h6>
                                      </div>
                                      <div style={{ width: '50%', height: '70px' }}>
                                          <DownloadsGraph data={functionDetails.downloads} />
                                      </div>
                                  </div>
                              </CardBody>
                          </Card>

                          {/* Runtime Information */}
                          <Card className="border-0 rounded shadow mt-4">
                              <CardBody>
                                  <h5 className="widget-title">Runtime Information</h5>
                                  <Badge color="primary" className="me-2">Language: {functionDetails.runtimeLanguage}</Badge>
                                  <Badge color="secondary">Framework: {functionDetails.runtimeFramework}</Badge>
                              </CardBody>
                          </Card>

                          {/* Code Examples */}
                          <Card className="border-0 rounded shadow mt-4">
                              <CardBody>
                                  <h5 className="widget-title">Code Examples</h5>
                                  <MultiLangCodeBlock codeBlocks={codeBlocks} />
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </Container>
          </section>

          <LoginModal isOpen={false} toggle={() => { }} />
      </React.Fragment>
    );
};

export default DeveloperFunctionProductPage;

