import React, { useState } from 'react';
import {
  Button,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { Highlight, themes } from "prism-react-renderer"

const MultiLangCodeBlock = ({ codeBlocks }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [copied, setCopied] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div >
      <Nav tabs>
        {codeBlocks.map((block, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: activeTab === index })}
              onClick={() => { toggle(index); }}
              style={{ cursor: 'pointer' }}
            >
              {block.language}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {codeBlocks.map((block, index) => (
          <TabPane key={index} tabId={index}>
            <div style={{ position: 'relative' }}>
              <Button
                color="primary"
                size="sm"
                style={{ position: 'absolute', top: 10, right: 10 }}
                onClick={() => handleCopy(block.code)}
              >
                {copied ? 'Copied!' : 'Copy'}
              </Button>
              <Highlight
                theme={themes.vsDark}
                code={block.code}
                language={block.language.toLowerCase()}
              >
                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                  <pre
                    style={{
                      ...style,
                      padding: '15px',
                      borderRadius: '5px',
                      overflowX: 'auto',
                    }}>
                    {tokens.map((line, i) => (
                      <div key={i} {...getLineProps({ line })}>
                        <span
                          style={{
                            display: 'inline-block',
                            width: '2em',
                            userSelect: 'none',
                            opacity: '0.3',
                          }}
                        >
                          {i + 1}
                        </span>
                        {line.map((token, key) => (
                          <span key={key} {...getTokenProps({ token })} />
                        ))}
                      </div>
                    ))}
                  </pre>
                )}
              </Highlight>
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default MultiLangCodeBlock;
