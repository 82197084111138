import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Card, CardBody, ListGroup, ListGroupItem, Button, Badge, Input,
} from 'reactstrap';

const ConsumerFunctionProductPage = () => {
  const [functionDetails, setFunctionDetails] = useState({
    title: 'Text-to-SQL',
    description: 'Converts natural language text into SQL queries, enabling users to interact with databases using plain language',
    placeholder: 'Enter your input here that you want to convert to SQL',
    author: 'AIMaster91',
    version: 'v2.10',
    rating: 3,
    downloads: [0, 100, 500, 200, 600, 700, 1200],
    parameters: [
      { name: 'language', description: 'The language to translate to', required: true },
    ]
  });

  const [formData, setFormData] = useState({
    prompt: '', // Placeholder for prompt input
  });

  // Handle input changes for each parameter
  const handleInputChange = (paramName, value) => {
    setFormData({
      ...formData,
      [paramName]: value
    });
  };

  const handleRun = () => {
    // Function to handle 'Run' button click
    console.log('Running with:', formData);
  };

  return (
    <React.Fragment>
      <section className="function-product-page">
        <Container>
          <Row>
            <Col lg="12">
              <Card className="public-profile border-0 rounded" style={{ zIndex: '1' }}>
                <CardBody>
                  <Row className="align-items-center">
                    <Col lg="10" md="9">
                      <h3 className="title mb-0">{functionDetails.title}</h3>
                      <small className="text-muted h6">{functionDetails.description}</small>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>

          {/* Prompt Input (Large Text Area) */}
          <Row>
            <Col lg="12">
              <Card className="border-0 rounded">
                <CardBody>
                  <Input
                    type="textarea"
                    placeholder={functionDetails.placeholder}
                    style={{ height: '200px', color: '#333', opacity: '0.7' }} // Large text area with placeholder styling
                    onChange={(e) => handleInputChange('prompt', e.target.value)}
                    className="mb-3"
                  />

                  {/* Horizontal Parameters and Run Button */}
                  <Row className="d-flex align-items-center">
                    <Col lg="9">
                      <ListGroup horizontal>
                        {functionDetails.parameters.map((param, index) => (
                          <ListGroupItem key={index} className="border-0 px-2">
                            <strong>{param.name}</strong> - {param.description}
                            {param.required && <Badge color="danger" className="ms-2">Required</Badge>}
                            <Input
                              type="text"
                              placeholder={`Enter ${param.name}`}
                              onChange={(e) => handleInputChange(param.name, e.target.value)}
                              className="mt-2"
                              style={{ display: 'inline-block', width: 'auto' }}
                            />
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Col>
                    <Col lg="3" className="text-end">
                      {/* RUN Button aligned right */}
                      <Button color="primary" onClick={handleRun}>
                        Run
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default ConsumerFunctionProductPage;
